import React, { useEffect } from 'react'
import Seo from '../../components/seo/seo'
import Layout from '../../components/layout/Layout'
import { useStaticQuery, graphql } from 'gatsby'

const Blog = () => {
  useEffect(() => {
    setTimeout(() => {
      if (window.$(window).width() > 767) {
        var rellax = new window.Rellax('.rellax')
      }
      window.$('.tile').on('click', function (e) {
        window.$('body').addClass('hide-content')
        e.stopPropagation()
      })
    }, 1000)
  }, [])

  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Blog" } } } }
        }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          uri
          slug
          title
          date(formatString: "MMM DD, YYYY")
          blogPost {
            readingTimeMinutes
          }
          tags {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
              email
              description
              avatar {
                url
              }
            }
          }
          content
          featuredImage {
            node {
              sourceUrl
              altText
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo
        title={'Blog | immence'}
        description={"We're always learning. Sometimes we write about it."}
      />
      <Layout>
        {/* hero section */}
        <section
          className="content-page bg-service blog_banner blogContent"
          id="service_page"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content_padd">
                  <div className="row">
                    <h2 className="hero-heading">
                      Explore our
                      <br />
                      <span className="blue-span"> Insights. </span>
                    </h2>
                  </div>
                  <div className="row">
                    <p className="section-main-para heading-setting mb-0">
                      We're always learning. Sometimes we write about it.
                    </p>
                    <p className="section-main-para heading-setting mb-0">
                      Catch a glimpse of our recent articles — we dig deep on
                      the most promising tech trends and offer plenty of
                      actionable best practices and hands-on analytics.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 center-item">
                <div className="service_img">
                  <img
                    src={require('../../img/heroImg/insights.png').default}
                    alt="insights"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end hero section */}
        <div className="sectionPadding">
          <div className="blogSection">
            <div className="container">
              <div className="row">
                {data?.allWpPost?.nodes?.map((rowData) => {
                  const removeTagContent = rowData.content.replace(
                    /(<([^>]+)>)/gi,
                    ''
                  )
                  const removeLineContent = removeTagContent.replace(
                    /\r?\n|\r/g,
                    ''
                  )
                  const removeSpaceContent = removeLineContent.replace(
                    /^\s+/g,
                    ''
                  )
                  const finalContent =
                    removeSpaceContent.substring(0, 150) + '...'

                  const tagList = rowData.tags.nodes.map((d) => d.name)

                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="blogInfo">
                        <a href={rowData?.uri}>
                          <img
                            src={rowData?.featuredImage?.node?.sourceUrl}
                            alt={rowData?.featuredImage?.node?.altText}
                            className="img-fluid"
                          />
                        </a>
                        <div className="blogPaddBox">
                          <div className="advertising">
                            <a href>{`${tagList}`}</a>
                          </div>
                          <h1 className="blogTitle">
                            <a
                              href={rowData?.uri}
                              dangerouslySetInnerHTML={{
                                __html: rowData?.title.replace(
                                  /(<([^>]+)>)/gi,
                                  ''
                                ),
                              }}
                            />
                          </h1>
                          <p className="desc">{finalContent}</p>
                          <div className="blogBox">
                            <div className="authorImg">
                              <img
                                src={rowData?.author?.node?.avatar?.url}
                                alt="name"
                                className="img-fluid userImg"
                              />
                            </div>
                            <div className="authorInfo">
                              <div>
                                <p className="authorName">
                                  {rowData?.author?.node?.name}
                                </p>
                              </div>
                              <div className="dateTime">
                                <p className="date">{rowData?.date}</p>
                                <div className="postTime">
                                  <span className="bull">•</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                    <path d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z" />
                                  </svg>
                                  <p>
                                    {rowData?.blogPost?.readingTimeMinutes} min
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Blog
